<script>
import Layout from "@/router/layouts/main";
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import StoreService from '@/services/store'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Configurações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  directives: { money: VMoney },
  data() {
    return {
      title: "Configurações",
      shippingSettings: [],
      useDeliveryClickEntregas: false,
      additionalDeliveryTime: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load(showLoading = true) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      StoreService.getStoreData().then(res => {
        this.useDeliveryClickEntregas = res.data.useDeliveryClickEntregas;
        this.additionalDeliveryTime = res.data.additionalDeliveryTime;

        this.shippingSettings = (res.data.shippingSettings || []).map(item => {
          return {
            ...item,
            price: item.price.toFixed(2).replace('.', ','),
          }
        });

        if (!this.shippingSettings || this.shippingSettings.length === 0) {
          this.addShipping();
        }

        if (showLoading) {
          loader.hide();
        }
      }).catch(() => {
        if (showLoading) {
          loader.hide();
        }
      })
    },
    save() {
      let validate = true;
      this.shippingSettings.forEach(item => {
        if (!item.maxDistance || !item.deliveryTime || !item.price) {
          validate = false;
        }
      });

      if (!validate) {
        return this.$toast.warning('Preencha todos os campos corretamente');
      }

      let loader = this.$loading.show();
      const data = {
        shippingSettings: this.shippingSettings,
      }

      StoreService.updateStore(data).then(() => {
        this.$toast.open('Dados de entrega atualizados com sucesso');
        this.load(false);

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar os dados');

        loader.hide();
      })
    },

    addShipping() {
      if (!this.shippingSettings) {
        this.shippingSettings = [];
      }

      this.shippingSettings.push({
        maxDistance: '',
        deliveryTime: '',
        price: '',
      })

      this.$forceUpdate();
    },
    removeShipping(index) {
      this.shippingSettings.splice(index, 1);
    },
    changeClickEntregas() {
      let loader = this.$loading.show();
      const data = {
        useDeliveryClickEntregas: this.useDeliveryClickEntregas,
      }

      StoreService.updateStore(data).then(() => {
        this.$toast.open('Dados atualizados com sucesso');

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar os dados');
        loader.hide();
      })
    },
    saveClickEntregasConfig() {
      let loader = this.$loading.show();
      const data = {
        additionalDeliveryTime: this.additionalDeliveryTime,
      }

      StoreService.updateStore(data).then(() => {
        this.$toast.open('Dados atualizados com sucesso');

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar os dados');
        loader.hide();
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-xs-12 col-lg-9">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Integrações</h4>
            <b-form-checkbox v-model="useDeliveryClickEntregas" switch @change="changeClickEntregas">
              <label>Utilizar entrega via Borzo Delivery (Click Entregas)</label>
            </b-form-checkbox>

            <div v-if="useDeliveryClickEntregas">
              <div class="row mt-3">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="additionalDeliveryTime">Adicionar no tempo de entrega (em minutos)</label>
                    <input id="additionalDeliveryTime" name="additionalDeliveryTime" type="tel" class="form-control" v-model="additionalDeliveryTime" />
                  </div>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-6">
                  <div class="text-sm-left">
                    <button type="button" class="btn btn-success btn-rounded" @click="saveClickEntregasConfig">Salvar Configuração</button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="!useDeliveryClickEntregas">
      <div class="col-xs-12 col-lg-9">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Regras de Entrega</h4>
            <p class="card-title-desc">Defina aqui as limitações para a área de entrega e o valor cobrado para cada faixa</p>
            <form>
              <div class="row" v-for="(data, index) in shippingSettings" :key="index">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="maxDistance">Raio de Entrega (em Km)</label>
                    <input id="maxDistance" name="maxDistance" type="tel" class="form-control" v-model="data.maxDistance" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="deliveryTime">Tempo Médio (em min.)</label>
                    <input id="deliveryTime" name="deliveryTime" type="text" class="form-control" v-model="data.deliveryTime" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="price">Taxa de Entrega</label>

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text">R$</span>
                      </div>
                      <input
                        id="price"
                        name="price"
                        class="form-control"
                        v-model="data.price"
                        v-money="money"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-1 mt-3 align-self-center">
                  <a class="action-icon text-danger" @click="removeShipping(index)">
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-6">
                  <div class="text-sm-left">
                    <b-button pill variant="outline-primary" @click="addShipping">+ Nova Regra</b-button>
                  </div>
                </div>

                <div class="col-6">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded" @click="save">Salvar Dados de Entrega</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  input[type="file"] {
    display: none;
  }
</style>